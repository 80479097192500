import React from 'react';
import './App.css';
import Dashboard from './screens/Dashboard/Dashboard';
import OrderForm from './screens/OrderForm'
import PaymentInstructions from './screens/PaymentInstructions'
import Landing from './screens/Landing';
import NotFound from './screens/NotFound';
import { toast } from 'react-toastify';

import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import PaymentForm from "./screens/PaymentForm";
import ProfileForm from "./screens/ProfileForm";

// Call it once in your app. At the root of your app is the best place
toast.configure()

const SmartRedirect = props => (
  props.match.params.order_id? (
    window.location = process.env.REACT_APP_API_SERVER + '/app' + props.match.url
  ):(
    window.location = process.env.REACT_APP_API_SERVER + '/app/paylink' + props.match.url
  )
);

// ROUTES ZONE
function App() {
  return (
    <Router>
      <div className="App" >
        {/* <Navigation/> */}
        <Switch>
          <Route exact path="/" component={SmartRedirect}/>
          <Route exact path="/dashboard" component={SmartRedirect}/>
          <Route exact path="/profile" component={SmartRedirect}/>
          <Route exact path="/create-order" component={SmartRedirect}/>
          <Route exact path="/register-payment/:order_id" component={SmartRedirect}/>
          <Route exact path="/payment-instructions/:order_id" component={SmartRedirect} />
          <Route path="/404" component={NotFound}/>
          <Route component={NotFound} />

        </Switch>
      </div>
    </Router>
  );
}

export default App;
