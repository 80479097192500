import React,{Component} from 'react';
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export default class ApurataPosButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'loading': false
        }
        this.createPosOrder = this.createPosOrder.bind(this);
    }
    createPosOrder(orderData) {
        let url = new URL(process.env.REACT_APP_APURATA_DOMAIN + "/pos/crear-orden-y-continuar");
        url.searchParams.append("order_id", orderData.id);
        url.searchParams.append("pos_client_id", orderData.merchant_channel.pos_config.client_id);

        url.searchParams.append("amount", orderData.amount);
        url.searchParams.append("description", orderData.description);
        url.searchParams.append("url_redir_on_canceled", window.location.href);
        url.searchParams.append("url_redir_on_rejected", window.location.href);
        url.searchParams.append("url_redir_on_success", window.location.href);

        url.searchParams.append("customer_data__name", orderData.payer_name);
        url.searchParams.append("customer_data__phone", orderData.payer_phone);
        url.searchParams.append("customer_data__email", orderData.payer_email);

        window.location.href = url.toString();
    }
    render() {
        if (this.state.loading) {
            return (
                <button className="btn btn-finance" disabled>
                    <FontAwesomeIcon
                        icon={faSpinner}
                        className="fa-spin"
                    />
                </button>
            );
        } else {
            if( "approved validated".includes(this.props.orderData.pos_status))
                return (
                    <button className="btn btn-white" onClick={()=>this.createPosOrder(this.props.orderData)}>{this.props.btntext}</button>
                )
            else if(this.props.orderData.pos_status==='rejected')
                return (
                    <button className="btn btn-finance" disabled>Solicitar</button>
                )
            else return(
                    <button className="btn btn-finance" onClick={()=>this.createPosOrder(this.props.orderData)}>Solicitar</button>
            )

        }
    }
}
