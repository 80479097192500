import Cookies from "js-cookie";

export function IsLoggedIn() {
    return getAuthorizationToken() != null;
}

export function getAuthorizationToken() {
    return Cookies.get("auth");
}

export function getHeaders() {
    let headers = {};
    let token = getAuthorizationToken();
    if (token) {
        headers['Authorization'] = "Bearer " + token;
    }
    return headers;
}