import React,{Component} from 'react';
import Navigation from "../components/Navigation";

export default class PaymentForm extends Component{

    constructor(props) {
        super(props);
        this.state = {
            orderInfo: {},
            voucher: '',
            file: null,
            image: null,
            base64:'',
            bank: 'BCP-052',
            operation: '',
            amount: '',
            paymentDate: new Date().toISOString().substring(0, 10),
            errorMsg: {}
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.clearStateForPost = this.clearStateForPost.bind(this)
    }
    componentDidMount() {
        this.fetchOrder();
    }
    fetchOrder() {
        fetch(process.env.REACT_APP_API_SERVER + "/api/v1/paylink/order/"+ this.props.match.params.order_id)
        .then(res => res.json())
        .then(
            (result) => {
                if(result.code!==401){
                    //console.log("result",result);
                    this.setState({orderInfo: result});
                    this.setState({amount: result.amount})
                }
            },
            (error) => {
                //window.location.href = '/';
                console.error(error);
            }
        )
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
        if(name === "voucher"){
            let file = event.target.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                this.setState({
                    image: URL.createObjectURL(file),
                    file: file,
                    base64: reader.result
                });
            };
        }
        console.log(this.state);
    }
    clearStateForPost() {
        return {
            paylink_order: this.props.match.params.order_id,
            voucher: this.state.base64,
            file_name: "Captura_" + this.state.file.lastModified + ".jpg",
            bank: this.state.bank,
            operation_number: this.state.operation,
            amount: this.state.amount === ''? 0: this.state.amount,
            payment_date: this.state.paymentDate
        }
    }
    handleSubmit(event) {
        console.log("Submit")
        event.preventDefault();
        fetch(process.env.REACT_APP_API_SERVER + '/api/v1/paylink/register_payment', {
            method: 'POST',
            body: JSON.stringify(this.clearStateForPost()),
        }).then(res => res.json())
        .then(
            (response) => {
                if (!response.success){
                    this.setState({errorMsg: response.errors})
                    console.log(this.state.errorMsg);
                    return;
                }
                else {
                    alert('Voucher subido exitosamente')
                    window.location.href = '/payment-instructions/'+this.props.match.params.order_id;
                }
            },
            (err) => {
                alert('Error: ' + err);
            }
        );
    }
    render(){
        return(
            <div className="container-fluid h-100 bg-form">
                <div style={{height:50}}/>
                <Navigation/>
                <div className="row form-pay vertical-center">
                    <div className="col-md-8 offset-md-2">
                        <br/>
                        <h4>Registra tu Pago</h4>
                        <hr/>
                        <p className="description"><i className="fas fa-shopping-cart"></i> { this.state.orderInfo.description }</p>
                        <div className="order-form">
                            <form onSubmit={this.handleSubmit}>
                                <div className="mb-3">
                                    <p>Sube la imagen o captura de tu Voucher</p>
                                    <div className="custom-file">
                                        <input
                                            name="voucher"
                                            type="file"
                                            accept="image/*"
                                            className="custom-file-input"
                                            onChange={this.handleInputChange} required={false}/>
                                        <label className="custom-file-label"></label>
                                        <ValidationMessage errors={this.state.errorMsg} name="voucher"/>
                                    </div>
                                </div>
                                {
                                    this.state.image?(
                                        <img alt="Previsualización de la constancia de pago" className="image-preview" src={this.state.image} />
                                    ):(<div/>)
                                }
                                <div className="form-group">
                                    <label htmlFor="">Banco donde se deposito el pago</label>
                                    <select name="bank"
                                            className="form-control"
                                            onChange={this.handleInputChange} required={false}>
                                        <option value="BCP-052">BCP</option>
                                        <option value="BBVA-461">BBVA</option>
                                        <option value="Interbank-804">Interbank</option>
                                    </select>
                                    <ValidationMessage errors={this.state.errorMsg} name="bank"/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Numero de operación</label>
                                    <input
                                        className="form-control"
                                        name="operation"
                                        type="text"
                                        onChange={this.handleInputChange} required={false}/>
                                    <ValidationMessage errors={this.state.errorMsg} name="operation_number"/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Monto pagado S/.</label>
                                    <input
                                        className="form-control"
                                        name="amount"
                                        type="number"
                                        step="0.01"
                                        value={this.state.orderInfo.amount}
                                        onChange={this.handleInputChange} required={false}/>
                                    <ValidationMessage errors={this.state.errorMsg} name="amount"/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Fecha del deposito</label>
                                    <input
                                        className="form-control"
                                        name="paymentDate"
                                        type="date"
                                        value={this.state.paymentDate}
                                        onChange={this.handleInputChange} required={false}/>
                                    <ValidationMessage errors={this.state.errorMsg} name="payment_date"/>
                                </div>
                                <button type="submit" className="btn btn-form btn-block">Subir voucher</button>
                            </form><br/>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
function ValidationMessage(props) {
    if (props.errors && props.errors[props.name] != null) {
        return <small className='text-danger'>{props.errors[props.name][0]}</small>
    }
    return null;
}