import {getHeaders, IsLoggedIn} from './../services/AuthServices';


function handleHttpError(response) {
    console.error("Http error:", response);
	// alert("Ups, tenemos un problema, inténtalo más tarde");
}

function handleConnectionError(err) {
    console.error("Connection error:", err);
	// alert("Ups, tenemos un problema, inténtalo más tarde");
}

export function fetchMerchantInfo() {
	if (!IsLoggedIn()) {
		return Promise.resolve({});
	}

    return fetch(process.env.REACT_APP_API_SERVER + "/api/v1/paylink/merchant", {
        headers: getHeaders()
    })
    	.then(
    		(response) => {
                if (!response.ok) {
                	handleHttpError(response);
                	return {};
                }
                return response.json();
            },
            handleConnectionError
        )
}

export function fetchBanksList(){
    return fetch(process.env.REACT_APP_API_SERVER + "/api/v1/paylink/banks", {
        headers: getHeaders()
    })
    	.then(
    		(response) => {
                if (!response.ok) {
                	handleHttpError(response);
                	return {};
                }
                return response.json();
            },
            handleConnectionError
        )
}