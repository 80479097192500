import React,{Component} from 'react';
import { Link } from 'react-router-dom';

import Navigation from './../components/Navigation';
import Footer from './../components/Footer';
import {fetchMerchantInfo} from './../services/UserServices';
import SimpleExpansionPanel from './../components/Accordion';

function CallToActionButton({userInfo, className}) {
    if (userInfo && Object.keys(userInfo).length) {
        return (
            <Link to="/dashboard"
               className={className}>
                Ver mis Órdenes
            </Link>
        )
    } else {
        return (
            <a href={process.env.REACT_APP_API_SERVER+"/paylink/register"}
               className={className}>
                Afiliate
            </a>
        )
    }
}

export default class Landing extends Component{

    constructor(props) {
        super(props);
        this.state = {
            loading: "",
            userInfo: {},
        };
    }

    componentDidMount() {
        fetchMerchantInfo().then(info => this.setState({userInfo: info}));
    }


    render(){
        return(
            <div className="container-fluid h-100 landing">
                <div style={{height:50}}/>
                <Navigation/>
                <div className="row landing-header">
                    <div className="col-md-10 offset-md-1">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="title">Gana Más,<br/>¡Financia a tus clientes!</div>
                                <p>Vende más desde tu ecommerce, whatsapp, instagram, facebook, tik-tok, correo, teléfono, o hasta ventas presenciales, y nosotros financiamos a tus clientes!</p>
                                <div className="row">
                                    <div className="col-6">
                                        <a href="#como-funciona" className="btn btn-orange btn-block">¿Cómo funciona?</a>
                                    </div>
                                    <div className="col-6">
                                        <CallToActionButton userInfo={this.state.userInfo} className="btn btn-white btn-block" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7 text-center">
                                <img src={process.env.PUBLIC_URL +"/img/phone.png"} alt="Phone"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row content">
                    <div className="col-md-10 offset-md-1">
                        <div className="row section">
                            <div className="col-md-12">
                                <div className="text-center">
                                    <h3>¿Qué es Apurata Paylink?</h3>
                                    <span className="line">&nbsp;</span>
                                    <p className="subtitle">Te ayudamos a vender más y ganar más dinero. <br/> Tu Vendes, y Nosotros:</p>
                                    <img src={process.env.PUBLIC_URL +"/img/award.png"} alt="Phone"/>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 offset-md-3">
                                        <p><i className="fas fa-check check fa-1x"></i> Financiamos a tus clientes</p>
                                        <p><i className="fas fa-check check fa-1x"></i> Te Ahorramos dinero</p>
                                        <p><i className="fas fa-check check fa-1x"></i> Te Ayudamos a Cobrar</p>
                                    </div>
                                    <div className="col-md-3 offset-md-1">
                                        <p><i className="fas fa-check check fa-1x"></i> Costo Zero (S/ 0)  de mantenimiento</p>
                                        <p><i className="fas fa-check check fa-1x"></i> Sin contracargos</p>
                                        <p><i className="fas fa-check check fa-1x"></i> Te organizamos</p>
                                    </div>
                                </div>
                                <div className="text-center">
                                <a href="#beneficios" className="btn btn-white">Conocer más <i className="fas fa-chevron-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="row how-use">
                            <div className="col-md-9 offset-md-2">
                                <div className="text-left">
                                    <h3>¿Quíen lo puede usar?</h3>
                                    <SimpleExpansionPanel/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 bg-girl" >
                    </div>
                </div>
                <div className="row how-works" id="como-funciona">
                    <div className="col-md-10 offset-md-1">
                        <div className="row section">
                            <div className="col-md-12">
                                <div className="text-center">
                                    <h3>¿Cómo funciona?</h3>
                                    <span className="line">&nbsp;</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6" style={{padding:0}} >
                        <img src={process.env.PUBLIC_URL +"/img/girl2.png"} alt="Girl"/>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-2">
                                <div className="text-right">
                                    <p className="steps">1</p>
                                </div>
                            </div>
                            <div className="col-10 vertical-center">
                                <p>Tu cliente recibe el link de cobro por mail, chat o lo encuentra publicado en redes sociales</p>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-2">
                                <div className="text-right">
                                    <p className="steps">2</p>
                                </div>
                            </div>
                            <div className="col-10 vertical-center">
                                <p>Al hacer clic en el link, tu cliente será llevado a “Checkout” con el valor a pagar. Escoge el medio de pago de su preferencia y paga.</p>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-2">
                                <div className="text-right">
                                    <p className="steps">3</p>
                                </div>
                            </div>
                            <div className="col-10 vertical-center">
                                <p>Tú y tu cliente recibirán un comprobante de la transacción. Así podrás entregar el producto/servicio a tu cliente.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row how-start">
                    <div className="col-md-10 offset-md-1">
                        <div className="row section">
                            <div className="col-md-12">
                                <div className="text-center">
                                    <h3>¿Cómo empezar?</h3>
                                    <span className="line">&nbsp;</span>
                                    <p className="subtitle">S/ 0 para empezar, S/ 0 de mantenimiento, paga solo por transacciones procesadas por Apurata</p>
                                </div>
                            </div>
                        </div>
                        <div className="row text-center">
                            <div className="col-md-3">
                                <img src={process.env.PUBLIC_URL +"/img/file.png"} alt="Icon" width="25%"/>
                                <br/><br/>
                                <p>Registrate con tus datos y los datos de tu empresa</p>
                            </div>
                            <div className="col-md-3">
                                <img src={process.env.PUBLIC_URL +"/img/check.png"} alt="Icon" width="25%"/>
                                <br/><br/>
                                <p>Solicita activación de tu cuenta</p>
                            </div>
                            <div className="col-md-3">
                                <img src={process.env.PUBLIC_URL +"/img/shopping.png"} alt="Icon" width="25%"/>
                                <br/><br/>
                                <p>Empieza a vender y generar órdenes hoy mismo</p>
                            </div>
                            <div className="col-md-3">
                                <img src={process.env.PUBLIC_URL +"/img/money.png"} alt="Icon" width="25%"/>
                                <br/><br/>
                                <p>Empieza a cobrar hoy mismo</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row how-ready">
                    <div className="col-md-10 offset-md-1">
                        <div className="row section">
                            <div className="col-md-12">
                                <div className="text-center">
                                    <br/>
                                    <h3>¿Listo para empezar?</h3>
                                    <span className="line">&nbsp;</span>
                                </div>
                                <br/><br/><br/><br/>
                                <div className="row text-center">
                                    <div className="col-md-4 offset-md-2">
                                        <CallToActionButton userInfo={this.state.userInfo} className="btn btn-orange btn-block" />
                                    </div>
                                    <div className="col-md-4">
                                        <a href={process.env.REACT_APP_API_SERVER+"/paylink/register"} className="btn btn-white btn-block">Contáctanos</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row benefits" id="beneficios">
                    <div className="col-md-10 offset-md-1">
                        <div className="row section">
                            <div className="col-md-12">
                                <div className="text-center">
                                    <h3>Beneficios</h3>
                                    <span className="line">&nbsp;</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="benefits-card">
                                    <img src={process.env.PUBLIC_URL +"/img/people.png"} alt="Phone"/>
                                    <br/><br/>
                                    <h6><b>Financiamos a tus Clientes</b></h6><br/>
                                    <p><i className="fas fa-check check fa-1x"></i> Incrementamos la orden promedio 87%</p>
                                    <p><i className="fas fa-check check fa-1x"></i> Aumentamos la conversión 26%</p>
                                    <p><i className="fas fa-check check fa-1x"></i> Rango de compras entre S/ 100 y S/ 1,000</p>
                                </div>
                                <br/>
                            </div>
                            <div className="col-md-4">
                                <div className="benefits-card">
                                    <img src={process.env.PUBLIC_URL +"/img/saved.png"} alt="Phone"/>
                                    <br/><br/>
                                    <h6><b>Ta ayudamos a ahorrar</b></h6><br/>
                                    <p>3.99% + $0.30 centavos por transacción</p>
                                    <p><i className="fas fa-check check fa-1x"></i> Financiamiento instantáneo a tu cliente</p>
                                    <p><i className="fas fa-check check fa-1x"></i> Pagos en agente </p>
                                    <p><i className="fas fa-check check fa-1x"></i> Pagos en banco</p>
                                    <p><i className="fas fa-check check fa-1x"></i> Pagos por transferencia bancaria</p>
                                    <p><i className="fas fa-check check fa-1x"></i> Pagos por transferencia interbancaria</p>
                                </div>
                                <br/>
                            </div>
                            <div className="col-md-4">
                                <div className="benefits-card">
                                    <img src={process.env.PUBLIC_URL +"/img/industry.png"} alt="Phone"/>
                                    <br/><br/>
                                    <h6><b>Te Ayudamos a gestionar tu negocio</b></h6><br/>
                                    <p><i className="fas fa-check check fa-1x"></i> Envía mensajes de recordatorio de pago</p>
                                    <p><i className="fas fa-check check fa-1x"></i> Consulta el estado de cada orden en tu celular</p>
                                    <p><i className="fas fa-check check fa-1x"></i> Recibe notificaciones tiempo real cuando recibes un pago</p>
                                    <p><i className="fas fa-check check fa-1x"></i> S/ 0 costo de mantenimiento por estas herramientas</p>
                                    <p><i className="fas fa-check check fa-1x"></i> Descarga tu data sin costo</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row how-start">
                    <div className="col-md-10 offset-md-1">
                        <div className="row section">
                            <div className="col-md-12">
                                <div className="text-center">
                                    <br/>
                                    <h3>Empieza tu registro ahora</h3>
                                    <span className="line">&nbsp;</span>
                                </div>
                                <br/>
                                <div className="row text-center">
                                    <div className="col-md-4 offset-md-4">
                                        <CallToActionButton userInfo={this.state.userInfo} className="btn btn-orange btn-block" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>

            </div>

        );
    }
}
