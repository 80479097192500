import React,{Component} from 'react';
// import '../styles/Notfound.css';
import Navigation from './../components/Navigation';

export default class NotFound extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading: "",
        };
    }

    componentDidMount() {
    }

    render(){
        return(
           <div className="error-page h-100">
               <div style={{height:50}}/>
               <Navigation/>
               <section className="mt-5 error-container text-center">
                   {/* <span>4</span>
                   <span><span className="screen-reader-text">0</span></span>
                   <span>4</span> */}
               </section>
               <h1 className="text-center ">Pagina no encontrada</h1>
           </div>
        );
    }
}



