import React,{Component} from 'react';


export default class Footer extends Component{
    render(){
      return(
          <footer className="main-footer">
              <div className="row">
                  <div className="col-md-10 offset-md-1">
                      <div className="container row">
                          <div className="col-md-6 col-xs-12">
                              <img alt="Logo de Apurata" className="main-footer__brand" data-src="https://static.apurata.com/img/logo-dark.svg" src="https://static.apurata.com/img/logo-dark.svg"/><br/>
                              <span className="main-footer__copyright">Apurata 2020 © Todos los Derechos Reservados</span>
                              <img alt="Íconos de confianza" data-src="https://static.apurata.com/img/trust.png" src="https://static.apurata.com/img/trust.png"/> <br/><br/>
                              <img alt="Logo de la SBS" data-src="https://apurata.s3-us-west-2.amazonaws.com/static/sbs_logotipo_texto.svg"
                                   src="https://apurata.s3-us-west-2.amazonaws.com/static/sbs_logotipo_texto.svg"/><br/><br/>
                          </div>
                          <div className="col-md-2 col-xs-12 offset-md-2">
                              <h4 className="main-footer__title">Ayuda</h4>
                              <nav className="main-footer__nav">
                                  <a href="#como-funciona">¿Como Funciona?</a>
                                  <a href={process.env.REACT_APP_API_SERVER+"/paylink/register"}>Contacto</a>
                              </nav>
                          </div>
                          <div className="col-md-2 col-xs-12">
                              <h4 className="main-footer__title">Contacto</h4>
                              <a className="main-footer__phone" href="tel:943-690-320">
                                  <i className="fa fa-phone"></i> 943-690-320</a>
                              <nav className="main-footer__social">
                                  <a className="main-footer__social-item"
                                     href="https://www.facebook.com/ApurataPrestamos/"><i className="fab fa-facebook"></i></a>
                                  <a className="main-footer__social-item" href="https://twitter.com/apurata_">
                                      <i className="fab fa-twitter"></i></a>
                              </nav>
                          </div>
                      </div>
                  </div>
              </div>
          </footer>
        );
    }
}

