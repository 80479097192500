import React, { Component } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { format } from 'react-string-format';

import Navigation from '../../components/Navigation';
import ShareButton from '../../components/ShareButton'
import CreateOrderButton from '../../components/CreateOrderButton'
import { getHeaders } from '../../services/AuthServices';
import { fetchMerchantInfo } from '../../services/UserServices';
import "./Dashboard.css"


function FilterButton({ changeFilter, newFilterId, currentFilter }) {
    let selected;
    if (currentFilter.id === newFilterId) {
        selected = true;
    } else {
        selected = false;
    }
    let newFilter;
    for (let i = 0; i < tabsInfo.length; ++i) {
        if (tabsInfo[i].id === newFilterId) {
            newFilter = tabsInfo[i];
            break;
        }
    }
    return (
        <button type="button" className={"filter-btn btn " + (!selected? "text-white btn-secondary" : "btn-info")} onClick={() => changeFilter(newFilter)}>{newFilter.display}</button>
    );
}

function FilterComponent({ changeFilter, currentFilter }) {
    return (
        <ul className="nav nav-pills">
            {tabsInfo.map((tab, i) => {
                return <FilterButton changeFilter={changeFilter} key={tab.id} newFilterId={tab.id} currentFilter={currentFilter} />;
            })}
        </ul>
    );
}

function LastInteraction({ data, resendNotification, order, cancelOrder, paymentByOtherChannel }) {
    if (data.status !== undefined) {
        return (
            <div className="col-md-10 offset-md-1">
                <div className="card" style={{ textAlign: "center", minHeight: 'inherit' }}>
                    <div className="order-field">
                        <div className="labels">
                            <b>Notificación:</b>
                        </div>
                        <div className="values">
                            <p>{data.status}</p>
                        </div>
                    </div>

                    <button onClick={() => resendNotification(order.id)} id="share-paylink" className="btn btn-sm">
                        <b>Reenviar</b>
                    </button>
                </div>
            </div>
        );
    }
    return (
        order.payment_status === 'PENDING'? (
            <div className="col-md-10 offset-md-1">
                <div className="row modal-buttons">
                    <div className="col-md-12">
                        <button onClick={() => resendNotification(order.id)} className="btn btn-sm btn-activate btn-form btn-block">
                            <b>Enviar notificación</b>
                        </button>
                    </div>
                    <hr/>
                    <div className="col-md-12">
                        <button onClick={() => paymentByOtherChannel(order.id)} className="btn btn-sm btn-activate turqueza btn-block">
                            <b>Pagado por otro medio</b>
                        </button>
                    </div>
                    <hr/>
                    <div className="col-md-12">
                        <button onClick={() => cancelOrder(order.id)} className="btn btn-sm btn-activate btn-danger btn-block">
                            <b>Cancelar Orden</b>
                        </button>
                    </div>
                </div>
                <br/>
            </div>
        ):(<div/>)
    );
}

function paymentStatus(payment_status) {
    switch (payment_status) {
        case 'PENDING':
            return 'Pendiente'
        case 'REPAYED':
            return 'Pagado'
        case 'VALIDATING':
            return 'Validando'
        default:
            return 'Pendiente'
    }
}

function channelPayment(channel) {
    switch (channel) {
        case 'FINANCING':
            return 'Financiamiento'
        case 'TRANSACTION':
            return 'Transación bancaria'
        case 'OTHER':
            return 'Otro medio'
        default:
            return channel
    }
}

function LastPayment({ data, title }) {
    if (data !== undefined) {
        return (
            <div className="text-center">
                <h3 className="add">{title || "Última Orden:"}</h3>
                <div className="order-field">
                    <div className="labels">
                        <b>Descripción:</b>
                    </div>
                    <div className="values">
                        <p>{data.description}</p>
                    </div>
                </div>
                <div className="order-field">
                    <div className="labels">
                        <b>Monto:</b>
                    </div>
                    <div className="values">
                        <p>S/ {parseFloat(data.amount).toFixed(2)}</p>
                    </div>
                </div>
                <div className="order-field">
                    <div className="labels">
                        <b>Estado de pago:</b>
                    </div>
                    <div className="values">
                        <p>{paymentStatus(data.payment_status)}</p>
                    </div>
                </div>
                <div className="order-field">
                    <div className="labels">
                        <b>Medio de pago:</b>
                    </div>
                    <div className="values">
                        <p>{data.payment_status==="PENDING"?'Ninguno':channelPayment(data.channel)}</p>
                    </div>
                </div>
                <div className="order-field">
                    <div className="labels">
                        <b>Cliente:</b>
                    </div>
                    <div className="values">
                        <p style={{ textTransform: "capitalize" }}>{data.payer_name}</p>
                    </div>
                </div>
                <div className="order-field">
                    <div className="labels">
                        <b>Cliente teléfono:</b>
                    </div>
                    <div className="values">
                        <p>{data.payer_phone}</p>
                    </div>
                </div>
                <div className="order-field">
                    <div className="labels">
                        <b>Cliente email:</b>
                    </div>
                    <div className="values">
                        <p>{data.payer_email}</p>
                    </div>
                </div>
                <hr />
                <b>Link pago: </b>
                <a href={data.paylink}>{data.paylink}</a>
                <br/><br/>
                <ShareButton
                    url={data.paylink}
                    title='Orden de pago'
                    text={format('Completa tu pago de {0} por S/{1}, haciendo click aquí', data.description, data.amount)} />
            </div>
        );
    }
    return <div/>
}

function RowPayments({ data, columns, viewPayment, filter}) {
    return (
        <tr>
            {
                columns.map(function (val, i) {
                    let cellVal = data[val.key]
                    let mobileClass = val.priority === 1 ? '' : 'only-desktop'
                    if (val.key === 'created') {
                        cellVal = formatter.format(new Date(data[val.key]))
                        cellVal = (<React.Fragment><p>{cellVal}</p><span className="only-mobile">{data.payer_name}</span></React.Fragment>)
                    }
                    else if (val.key === 'amount' || val.key === 'apurata_commission') {
                        cellVal = 'S/' + cellVal.toFixed(2)
                    }
                    else if (val.key === 'payment_status') {
                        cellVal = paymentStatus(cellVal)
                    }
                    else {
                        if (val.key === "active" || val.key === "paid") {
                            cellVal = cellVal ? "Si" : "No"
                        }
                    }
                    return <td onClick={() => viewPayment(data)} className={mobileClass} key={i}>{cellVal}</td>;
                })
            }
            {
                filter.id === 'PENDING'? (
                    <td style={{ textAlign: "center" }}>
                    <ShareButton
                        url={data.paylink}
                        title='Orden de pago'
                        text={format('Completa tu pago de {0} por S/{1}, haciendo click aquí', data.description, data.amount)} />
                    </td>
                ) : ('')
            }
        </tr>
    );
}

function TablePayments({ columns, data, filter, viewPayment }) {
    return (
        <div className="mt-3">
            {
                <table className="table table-responsive orders-table">
                    <thead className="thead-light">
                        <tr>
                            {
                                columns.map(function (val, i) {
                                    let mobileClass = val.priority === 1 ? '' : 'only-desktop'
                                    return <th key={i} className={mobileClass}>{val.title}</th>;
                                })
                            }
                            {
                                filter.id === 'PENDING'? ( <th>Compartir</th> ) : ('')
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.length > 0 ?
                                data.map(function (order, i) {
                                    let keys = Object.keys(filter.filter);
                                    for (let j = 0; j < keys.length; ++j) {
                                        if (order[keys[j]] !== filter.filter[keys[j]]) {
                                            return;
                                        }
                                    }
                                    return <RowPayments key={i} data={order} columns={columns} viewPayment={viewPayment} filter={filter}/>;
                                }) :
                                <tr />
                        }
                    </tbody>
                </table>
            }
        </div>
    );
}

function UserInfoSection({ user, setVisibleModalBank }) {
    const bank_account = (user.bank_accounts && user.bank_accounts.length) ? user.bank_accounts[0] : null;
    return (
        <div id="account" className="col-md-3 first-section text-white p-5 ">
            <div className="text-center">
                <div>{user.enable ? 'Activo' : 'Desactivo'}</div>
                <h2>{user.display_name}</h2>
                <p>RUC: {user.ruc}</p>
            </div>
            <div>
                <h6>Razon Social:</h6>
                <p>{user.razon_social}</p>
            </div>
            <div>
                <h6>Usuario:</h6>
                <p>{user.contact_name}</p>
            </div>
            <div>
                <h6>Celular:</h6>
                <p>{user.phone}</p>
            </div>
            <div>
                <h6>Correo:</h6>
                <p>{user.notification_emails}</p>
            </div>

            <div>
                {/* <span onClick={() => setVisibleModalBank(true)}><Edit fontSizeSmall={true} /></span> */}
                <h6>Cuentas:  </h6>

                {bank_account ? (<ul style={{ listStyle: 'none', padding: 0 }}>
                    <li>Banco:  {bank_account.bank_name}</li>
                    <li>Cuenta:  {bank_account.bank_account_no}</li>
                    <li>CCI:  {bank_account.bank_cci}</li>
                </ul>) :
                    <p />}
            </div>
            <h6>Opciones de pago para tus clientes:</h6>
            <a href="/profile" className="btn btn-white"> Activar opciones</a>
        </div>
    );
}

function ModalUpdateBankInfo({ visible, setVisibility, action }) {
    return (
        <Modal
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            open={visible}
            onClose={() => setVisibility(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 100,
            }}>
            <Fade in={visible}>
                <div className="container-fluid p-2" style={{ backgroundColor: "white", width: 400, margin: 10 }}>
                    <p className="text-center pt-3" style={{ fontWeight: 'bold' }}>Cuenta bancaria</p>
                    <div className="row p-3">
                        <div className="offset-md-1 col-md-10">
                            <form>
                                <div className="form-group">
                                    <label for="bankSelect">Banco</label>
                                    <select className="form-control" id="bankSelect">
                                        <option>BCP</option>
                                        <option>Interbank</option>
                                        <option>BBVA</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Numero de cuenta</label>
                                    <input type="text" className="form-control" placeholder="xxx-xxx-xxxxx" />
                                </div>
                                <div className="form-group">
                                    <label>Numero de cuenta Interbancario (CCI)</label>
                                    <input type="text" className="form-control" placeholder="xxx-xxx-xxxxx" />
                                </div>
                            </form>
                        </div>

                    </div>
                    <div className="row pr-3 pl-3">
                        <button onClick={() => action()}
                            className="btn btn-lg  btn-block btn-success">Guardar</button>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
}

function PaymentSection({ user, payments, last_payment, filter, changeFilter, viewPayment }) {
    let pending = 0;
    let repayed = 0;
    let disbursed = 0;
    if (payments.length) {
        payments.map(function (val, i) {
            if (val.order_status === 'PENDING') {
                pending += val.amount;
            } else if (val.order_status === 'REPAYED') {
                repayed += val.amount;
            } else if (val.order_status === 'DISBURSED') {
                disbursed += val.amount_disbursed;
            }
        })
    }

    let columnsData = filter.columns
    return (
        <div id="payment" className="col-md-9">
            <div className="container-fluid dashboard">
                <div className="row">
                    <div className="col-md-8">
                        {
                            !user.enable?(
                                <div className="alert alert-warning" role="alert">
                                    <strong>MODO DEMO</strong> - Tu cuenta aún no esta activada. Consultas o reclamos al 943 689 341.
                                </div>
                            ):(<div/>)
                        }
                        <p><b>Cobra con un link</b></p>
                        <p className="welcome">Bienvenid@ a {user.display_name}</p>
                    </div>
                    <div className="col-md-4 text-right">
                        <CreateOrderButton user={user}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        {
                            last_payment !== undefined?(
                                <div className="card" style={{ textAlign: "center" }}>
                                    <LastPayment data={last_payment} />
                                </div>
                            ):(<div/>)
                        }

                    </div>
                    {payments.length > 0 ?
                        (<div className="col-md-6">
                            <div className="card" style={{ textAlign: "center" }} >
                                <h3>Pendiente <i className="far fa-question-circle" data-target="#pending-explanation" data-toggle="collapse"></i>:</h3>
                                <div id="pending-explanation" className="collapse">
                                    El total de las ordenes cuyos pagos no existan o se esten validando
                                </div>
                                <p className="orders">
                                    S/ {parseFloat(pending).toFixed(2)}
                                </p>
                                <h3>Pagado <i className="far fa-question-circle" data-target="#repayed-explanation" data-toggle="collapse"></i>:</h3>
                                <div id="repayed-explanation" className="collapse">
                                    El total de los pagos validados por Apurata
                                </div>
                                <p className="orders">
                                    S/ {parseFloat(repayed).toFixed(2)}
                                </p>
                                <h3>Cobrado <i className="far fa-question-circle" data-target="#disbursed-explanation" data-toggle="collapse"></i>:</h3>
                                <div id="disbursed-explanation" className="collapse">
                                    El total desembolsado a su fábrica
                                </div>
                                <p className="orders">
                                    S/ {parseFloat(disbursed).toFixed(2)}
                                </p>
                            </div>
                        </div>) : <div />
                    }
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <FilterComponent changeFilter={changeFilter} currentFilter={filter} />
                        <TablePayments filter={filter} columns={columnsData} data={payments} viewPayment={viewPayment} />
                    </div>
                </div>
            </div>
        </div>
    );
}

function ModalPaymentDetail({ visible, closeModal, data, payment, resendNotification, cancelOrder, paymentByOtherChannel}) {
    return (
        <Modal
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            open={visible}
            onClose={closeModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 100,
            }}>
            <Fade in={visible}>
                <div className="container-fluid p-2" style={{ backgroundColor: "white", width: 450, margin: 10 }}>
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="modal-detail">
                                <LastPayment title={"Detalle"} data={payment} />
                            </div>
                        </div>
                        <LastInteraction data={data}
                                         resendNotification={resendNotification}
                                         order={payment}
                                         cancelOrder={cancelOrder}
                                         paymentByOtherChannel={paymentByOtherChannel}/>
                    </div>
                </div>
            </Fade>
        </Modal>
    );

}

export default class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: "",
            userInfo: {},
            payments: {},
            filter: tabsInfo[0],
            modalVisible: false,
            paymentSelect: {},
            paymentDetail: [],
            modalBankVisible: false,
        };
        this.setVisibilityBankModal = (val) => { this.setState({ modalBankVisible: val }) };
        this.changeFilter = this.changeFilter.bind(this);
        this.viewPayment = this.viewPayment.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.resendNotification = this.resendNotification.bind(this);
        this.cancelOrder = this.cancelOrder.bind(this);

        this.myRef = React.createRef();
        this.menuItems = [
            // {
            //     title: "Ordenes",
            //     action: () => window.location.href = "/#payment"
            // },
            {
                title: "Crear orden",
                action: () => window.location.href = "/create-order/"
            },
            {
                title: "Perfil",
                action: () => { window.location.href = "/profile" }
            },
            {
                title: "Cerrar Sesión",
                action: () => window.location.href = process.env.REACT_APP_API_SERVER + "/logout?next=/paylink",
            }
        ];
    }

    componentDidMount() {
        fetchMerchantInfo().then(
            (info) => {
                if (Object.keys(info).length === 0) {
                    window.location.href = process.env.REACT_APP_API_SERVER + '/logout?next=/login?next=/paylink';
                    return;
                }
                this.setState({userInfo: info});
                this.fetchOrders();
                document.getElementsByClassName('dashboard-curtain')[0].classList.remove('active');
            }
        );
    }

    /*    scrollToAccount(){
            const anchor = document.querySelector('#cuenta')
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }*/

    fetchOrders() {
        fetch(process.env.REACT_APP_API_SERVER + "/api/v1/paylink/orders", {
            headers: getHeaders()
        })
            .then(res => res.json())
            .then(
                (result) => {
                    console.log("PAYMENTS", result);
                    if (result.code !== 401) {
                        this.setState({ payments: result });
                    }
                },
                (error) => {
                    /*console.error(error);*/
                }
            )
    }

    resendNotification(orderId) {
        fetch(process.env.REACT_APP_API_SERVER + "/api/v1/paylink/order/" + orderId + "/notification", {
            headers: getHeaders()
        })
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    alert('Notificacion enviada')
                }
            },
            (error) => {
                /* console.error(error);*/
            }
        )
    }


    cancelOrder(orderId){
        fetch(process.env.REACT_APP_API_SERVER + "/api/v1/paylink/order/" + orderId + "/cancel",{
            method: 'POST',
            headers: getHeaders()
        })
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    alert('Order Cancelada')
                    this.closeModal();
                    this.fetchOrders();
                }
            },
            (error) => {
                /* console.error(error);*/
            }
        )
    }

    paymentByOtherChannel(orderId){
        fetch(process.env.REACT_APP_API_SERVER + '/api/v1/paylink/register_payment_channel', {
            method: 'POST',
            body: JSON.stringify({
                paylink_order : orderId,
                channel: 'OTHER',
                message: false,
            }),
        }).then(res => res.json())
            .then(
                (response) => {
                    if (!response.success){
                        return;
                    }
                    else {
                        alert('Se guardaron tus cambios.');
                        window.location.href = '/dashboard';
                    }
                },
                (err) => {
                    alert('Error: ' + err);
                }
            );
    }

    viewPayment(data) {
        this.setState({ paymentSelect: data });
        this.setState({ modalVisible: true });
        fetch(process.env.REACT_APP_API_SERVER + "/api/v1/paylink/order/" + data.id + "/interactions", {
            headers: getHeaders()
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.code !== 401)
                        this.setState({ paymentDetail: result, modalVisible: true });
                    /*else {
                        this.setState({ modalVisible: true });
                    }*/
                },
                (error) => {
                    /* this.setState({  modalVisible: true });*/
                    /* console.error(error);*/
                }
            );

    }

    changeFilter(value) {
        this.setState({ filter: value });
    }

    closeModal() {
        this.setState({ modalVisible: false });
    }

    storageBankAccounts(data) {
        this.setVisibilityBankModal(false);
        fetch(process.env.REACT_APP_API_SERVER + "/api/v1/paylink/merchant/register_data", {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(
                {
                    "bank_accounts": [
                        {
                            "bank_name": "123",
                            "bank_account_no": "123",
                            "bank_cci": "123"
                        }]
                }
            ),
            headers: getHeaders()
        })
            .then(res => res.json())
            .then(
                (result) => {
                },
                (error) => {
                    //window.location.href = '/';
                    console.error(error);
                }
            )
    }

    render() {
        const { payments, userInfo, filter, modalVisible, paymentDetail, paymentSelect } = this.state;
        const last_payment = payments[0];
        return (
            <React.Fragment>
                <div style={{ height: 50 }} />
                <Navigation menuItems={this.menuItems} />
                <div className="dashboard-curtain active" />
                <div className="container-fluid h-100">
                    <div className="row h-100">
                        <PaymentSection
                            user={userInfo}
                            payments={payments}
                            last_payment={last_payment}
                            filter={filter}
                            changeFilter={this.changeFilter}
                            viewPayment={this.viewPayment}
                        />
                        <UserInfoSection
                            user={userInfo}
                            setVisibleModalBank={this.setVisibilityBankModal.bind(this)} />
                    </div>
                    <ModalPaymentDetail
                        payment={paymentSelect}
                        visible={modalVisible}
                        closeModal={this.closeModal}
                        data={paymentDetail}
                        resendNotification={this.resendNotification}
                        cancelOrder = {this.cancelOrder}
                        paymentByOtherChannel = {this.paymentByOtherChannel}/>

                    <ModalUpdateBankInfo
                        visible={this.state.modalBankVisible}
                        setVisibility={this.setVisibilityBankModal.bind(this)}
                        action={() => this.storageBankAccounts()} />
                </div>
            </React.Fragment>
        );
    }
}


const baseColumnsData = [
    {
        key: "created",
        title: "Fecha",
        priority: 1
    },
    {
        key: "description",
        title: "Detalle",
        priority: 2
    },
    {
        key: "payer_name",
        title: "Nombre",
        priority: 2
    },
    {
        key: "payer_email",
        title: "Email",
        priority: 2
    },
    {
        key: "payer_phone",
        title: "Teléfono",
        priority: 2
    },
    {
        key: "active",
        title: "Activo",
        priority: 2
    },
    {
        key: "amount",
        title: "Monto",
        priority: 1
    },
]
const tabsInfo = [
    {
        id: 'PENDING',
        display: "Pendientes",
        filter: {order_status: "PENDING"},
        columns: baseColumnsData.concat([
            {
                key: "paylink",
                title: "Link Pago",
                priority: 2
            },
            {
                key: "payment_status",
                title: "Pago",
                priority: 1
            },
            {
                key: "pos_status",
                title: "aCuotaz",
                priority: 1
            }
        ]),
    },
    {
        id: 'APPROVED',
        display: "Aprobados",
        filter: {pos_status: "approved"},
        columns: baseColumnsData,
    },
    {
        id: 'REPAYED',
        display: "Pagados",
        filter: {order_status: "REPAYED"},
        columns: baseColumnsData,
    },
    // {
    //     id: 'DISBURSED',
    //     display: "Cobrados",
    //     filter: {order_status: "DISBURSED"},
    //     columns: baseColumnsData.concat([
    //         {
    //             key: "apurata_commission",
    //             title: "Comision pagada",
    //             priority: 1
    //         },
    //     ]),
    // },
    {
        id: 'CANCELED',
        display: "Cancelados",
        filter: {order_status: "CANCELED"},
        columns: baseColumnsData,
    }
]

const formatter = new Intl.DateTimeFormat('es-ES', {
    day: '2-digit',
    month: 'short'
});
