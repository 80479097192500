import React from 'react';
import ShareButton from '../components/ShareButton'


export function MessagePaymentStatus({status, merchant}){
    if(status==='VALIDATING'){
        return (
            <div className="row h-100 head-instructions vertical-center">
                <div className="col-md-12">
                    <i className="far fa-check-circle fa-8x"></i>
                    <h3>¡Listo!</h3>
                    <h4>Tu pago esta siendo validado.</h4>
                </div>
            </div>
        );
    }else if(status==='CANCELED'){
        return (
            <div className="row h-100 head-instructions vertical-center">
                <div className="col-md-12">
                    <i className="fas fa-ban fa-8x"></i>
                    <h3>Orden Cancelada</h3>
                    <h4>Lo sentimos esta orden ya expiró.</h4>
                </div>
            </div>
        );
    }else if(status==='REPAYED'){
        return (
            <div className="row h-100 head-instructions vertical-center">
                <div className="col-md-12">
                    <i className="fas fa-shopping-cart fa-8x"></i>
                    <h3>¡Tu compra se realizó con éxito!</h3>
                    <h5>{merchant} programará la entrega de tu compra</h5>
                </div>
            </div>
        );
    }
}

export function CardsByOption({cards, creditCards, order}) {
    return (
         <div className="row">
             {
                 creditCards.map(function(card){
                     if( cards.includes(card.id) ){
                         return (
                             <div className="col-md-4" key={card.id}>
                                 {
                                     (card.id!==5 && card.id!==6)? (
                                         <div className="credit-card animation">
                                             <div className="float-right">
                                                 <ShareButton url={card.account} icon={'far fa-copy fa-2x'}></ShareButton>
                                             </div>
                                             <img src={process.env.PUBLIC_URL +"/img/"+card.logo} alt="Logo de tarjeta"/>
                                             <br/>
                                             <div>Titular: <b>{card.title}</b></div>
                                             <div dangerouslySetInnerHTML={{__html: card.content}} />
                                         </div>
                                     ):(
                                         order.merchant_channel[card.status]==="ENABLED"?(
                                             <div className="credit-card animation">
                                                 <div className={card.color}>
                                                     <img src={process.env.PUBLIC_URL +"/img/"+card.logo} alt="Logo de tarjeta"/>
                                                     <br/>
                                                     <div>Titular: <b>{order.merchant_display_name}</b></div>
                                                     <div>Celular: <b>{order.demo? order.merchant_phone: order.merchant_channel[card.phone]}</b></div>
                                                     <a href={'tel:'+ order.merchant_phone} className="btn btn-contact"><i className="fas fa-user-plus"></i> Agregar contacto</a>
                                                 </div>
                                             </div>
                                         ):(<div/>)

                                     )
                                 }
                             </div>
                         )
                     }
                 })
             }

         </div>
    )
}
