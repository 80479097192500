import React, { Component } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/icons/Menu';

import {IsLoggedIn} from './../services/AuthServices';
import {fetchMerchantInfo} from './../services/UserServices';


export default class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visibleMenu: false,
            userInfo: {},
        }
        // this.menuItems = props.menuItems || MenuItemsDefault;
        // this.openMenu = this.openMenu.bind(this);
        // this.customAction = this.customAction.bind(this);
        // for(let item of this.menuItems){
        //     item.customAction = this.customAction;
        // }
    }

    openMenu() {
        const { visibleMenu } = this.state;
        this.setState({ visibleMenu: !visibleMenu });
    }

    componentDidMount() {
        fetchMerchantInfo().then(info => { this.setState({userInfo: info}); console.log("USERINFO", info) });
    }

    // customAction(item) {
    //     item.action();
    //     this.openMenu();
    // }

    getMainActionButton() {
        if (Object.keys(this.state.userInfo).length > 0) {     // If logged in and have merchant
            return (
                <Link to="/dashboard">
                    <li className="list-group-item list-group-item-action">
                        Dashboard
                    </li>
                </Link>
            );
        } else if (IsLoggedIn()) {      // Logged in without merchant info
            return (
                <a href={process.env.REACT_APP_API_SERVER+"/paylink/register"}>
                    <li className="list-group-item list-group-item-action">
                        Afiliate
                    </li>
                </a>
            );
        } else {        // Not logged in
            return (
                <li onClick={() => window.location.href = process.env.REACT_APP_API_SERVER + "/login?next=/paylink"}
                    className="list-group-item list-group-item-action">
                    Iniciar sesión
                </li>
            );
        }
    }

    render() {
        return (
            <AppBar color="inherit" position="fixed" style={{ flexGrow: 1, }}>
                <Toolbar >
                    <Link className="header" to="/"  >
                        <img src={process.env.PUBLIC_URL + "/img/logo-dark.svg"} alt="Logo" />
                        <div>
                            <Typography style={{ color: "#000", margin: "12px", fontSize: "14px" }} >
                                | Mejores Prestamos
                  </Typography>
                        </div>

                    </Link>
                    <p style={{ flexGrow: 1 }} />
                    <Button onClick={() => this.openMenu()} color="inherit"><Menu /></Button>
                </Toolbar>
                {this.state.visibleMenu ?
                    <div className="menu">
                        <ul className="list-group">
                            { this.getMainActionButton() }
                            { IsLoggedIn() ?
                                <li
                                    onClick={() => window.location.href = process.env.REACT_APP_API_SERVER + "/logout?next=/paylink"}
                                    className="list-group-item list-group-item-action">
                                    Cerrar Sesión
                                </li> : null }
                        </ul>
                    </div> : <div />}
            </AppBar>
        );
    }
}

