import React,{Component} from 'react';
import {toast} from "react-toastify";
import Footer from './../components/Footer'
import ApurataPosButton from '../components/ApurataPosButton'
import Navigation from './../components/Navigation';
import {MessagePaymentStatus,CardsByOption} from './../components/Various';

function ChannelsOptions({merchant_channel, apurataPosCard, changeOption, comingSoon}) {
    const merchant_channels = merchant_channel || {}
    return(
        <div>
            <div className="text-center info">Realiza el pago por cualquiera de las siguientes opciones.</div>
            <div className="row all-options">
                {
                    merchant_channels.financing_status==="ENABLED"?(
                        apurataPosCard
                    ):(<div/>)
                }
                {
                    merchant_channels.transaction_status==="ENABLED"?(
                        <React.Fragment>
                            <div className="col-md-4">
                                <div className="section-cards">
                                    <div className="option-card blue">
                                        <h6>Transferencia Directa</h6>
                                        <div onClick={()=>changeOption(0)}>
                                            <img src={process.env.PUBLIC_URL +"/img/bcp.png"} alt="Logo"/>&nbsp;
                                            <img src={process.env.PUBLIC_URL +"/img/bbva.png"} alt="Logo"/>&nbsp;
                                            <img src={process.env.PUBLIC_URL +"/img/inter.png"} alt="Logo"/>
                                        </div>
                                        <small><i className="far fa-check-circle gold"></i> Apurata garantiza que recibirás tu producto</small> <br/>
                                        <div className="text-right">
                                            <a href="#openSteps" onClick={()=>changeOption(0)} className="btn btn-right">Pagar</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="section-cards">
                                    <div className="option-card blue">
                                        <h6>Transferencia Interbancaria</h6>
                                        <div className="content">Todos los bancos y cajas</div>
                                        <small><i className="far fa-check-circle gold"></i> Apurata garantiza que recibirás tu producto</small> <br/>
                                        <div className="text-right">
                                            <a href="#openSteps" onClick={()=>changeOption(1)} className="btn btn-right">Pagar</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="section-cards">
                                    <div className="option-card orange">
                                        <h6>Pago en Agente</h6>
                                        <div onClick={()=>changeOption(2)}>
                                            <img src={process.env.PUBLIC_URL +"/img/bcp.png"} alt="Logo"/>&nbsp;
                                            <img src={process.env.PUBLIC_URL +"/img/bbva.png"} alt="Logo"/>
                                        </div>
                                        <small><i className="far fa-check-circle gold"></i> Apurata garantiza que recibirás tu producto</small> <br/>
                                        <div className="text-right">
                                            <a href="#openSteps" onClick={()=>changeOption(2)} className="btn btn-right">Pagar</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    ):(<div/>)
                }
                {
                    merchant_channels.yape_status==="ENABLED" || merchant_channels.plin_status==="ENABLED"?(
                        <div className="col-md-4">
                            <div className="section-cards">{/* coming-soon*/}
                                <div className="option-card purple">
                                    <h6>Pago por Yape o Plin</h6>
                                    <img src={process.env.PUBLIC_URL +"/img/yape.png"} alt="Logo"/>&nbsp;
                                    <img src={process.env.PUBLIC_URL +"/img/plin.png"} alt="Logo"/>
                                    <br/>
                                    <small>Pago Directo a Proveedor (sin Garantía Apurata)</small> <br/>
                                    <div className="text-right">
                                        <a href="#openSteps" onClick={()=>changeOption(3)} className="btn btn-right">Pagar</a>
                                    </div>
                                </div>
                                {/*<div className="centered">Próximamente</div>*/}
                            </div>
                        </div>
                    ):(<div/>)
                }
                {/*<div className="col-md-4">
                    <div className="section-cards">
                        <div className="option-card green">
                            <h6>Pago con tarjeta de crédito</h6>
                            <img src={process.env.PUBLIC_URL +"/img/visa.png"} alt="Logo"/>&nbsp;
                            <img src={process.env.PUBLIC_URL +"/img/mastercard.png"} alt="Logo"/>
                            <br/>
                            <small></small> <br/>
                            <div className="text-right">
                                <a onClick={()=>comingSoon()} className="btn btn-right">Pagar</a>
                            </div>
                        </div>
                        <div className="centered">Próximamente</div>
                    </div>
                </div>*/}
            </div>
        </div>
    )
}
export default class PaymentInstructions extends Component{

    constructor(props) {
        super(props);
        this.state = {
            orderInfo: {},
            selectOption: {},
            stepsVisible: false,
            swapStep: true,
            posLandingConfig: {},
            showMessage: true,
            optionYape: false,
        };
        this.changeOption = this.changeOption.bind(this);
        this.changeStep = this.changeStep.bind(this);
        this.showOptions = this.showOptions.bind(this);
    }
    componentDidMount() {
        this.fetchOrder().then(order => this.fetchPOSConfig(order));
    }
    changeStep(step){
        this.setState({swapStep: step});
    }
    changeOption(item){
        this.setState({optionYape: false});
        this.setState({selectOption: categories[item]});
        this.setState({stepsVisible: true});
        if (item===3){
            this.setState({optionYape: true});
        }
    }
    back(){
        this.setState({stepsVisible: false});
    }
    comingSoon() {
        toast.dark('Esta opción aun no esta habilitada!', {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
        });
    }
    fetchOrder() {
        return fetch(process.env.REACT_APP_API_SERVER + "/api/v1/paylink/order/"+ this.props.match.params.order_id)
            .then(res => res.json())
            .then(
                (result) => {
                    if(result.code!==401){
                        console.log("Order:", result);
                        this.setState({orderInfo: result});
                        return result;
                    }
                },
                (error) => {
                    console.error(error);
                }
            )
    }
    fetchPOSConfig(order) {
        let client_id = order.merchant_channel?.pos_config?.client_id;
        if (!client_id) {
            return;
        }
        fetch(process.env.REACT_APP_API_SERVER + "/pos/client/" + client_id + "/landing_config_open")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({posLandingConfig: result});
                },
                (error) => {
                    console.error(error);
                }
            )
    }
    getAmountByCuota(amount, cuotas){
        var interest = 5 * (cuotas-1);
        var total = amount + (amount * interest/100);
        return Math.round(total/cuotas *100)/100;
    }
    getApurataPosCard(orderData, posLandingConfig) {
        if (Object.keys(posLandingConfig).length === 0) {
            // There is not posLandingConfig
            return;
        }
        const disable = orderData.pos_status==='rejected'? 'coming-soon':'';
        if (orderData.amount > posLandingConfig.min_amount && orderData.amount < posLandingConfig.max_amount) {
            return (
                <div className="col-md-4">
                    <div className={"section-cards "+disable}>
                        <div className="option-card green">
                            <h6>Financia tu Compra</h6>
                            <div className="content">Págalo en cuotas, desde S/{ this.getAmountByCuota(orderData.amount, posLandingConfig.max_steps) } quincenales</div>
                            <small><i className="far fa-check-circle gold"></i> Apurata garantiza que recibirás tu producto</small> <br/>
                            <ApurataPosButton orderData={orderData}></ApurataPosButton>
                        </div>
                    </div>
                </div>
            );
        }
    }
    showOptions(){
        this.setState({showMessage:false});
    }
    getMessageByStatus(order){
        const showOptions = this.showOptions;
        const name = '"'+order.merchant_display_name+'"';
        const start = "validated confirmed funded".includes(order.pos_status)? name:'';
        const end = "approved".includes(order.pos_status)? name:'';
        return (
            <div className="row h-100 head-instructions vertical-center">
                {
                    messageByStatus.map(function(item){
                        if( item.status.includes(order.pos_status)) {
                            return (
                                <div className="col-md-12" key={item.title}>
                                    <i className={item.icon + " fa-10x"}/><br/><br/>
                                    <h3>¡{item.title}!</h3>

                                    <h5>{start} {item.message} {end}</h5>
                                    {
                                        "approved validated".includes(order.pos_status)?(
                                            <ApurataPosButton orderData={order} btntext={item.btnText}/>
                                        ):(<div/>)
                                    }
                                    {
                                        item.btnOptions === true ? (
                                            <button onClick={()=>showOptions()} className="btn btn-white"> Ver otras opciones de pago</button>
                                        ):(<div/>)
                                    }

                                </div>
                            )
                        }
                    })
                }
            </div>
        );
    }
    clearStateForPost(channel) {
        return {
            paylink_order: this.props.match.params.order_id,
            channel: channel,
            message: true,
        }
    }
    channelSubmit(channel) {
        fetch(process.env.REACT_APP_API_SERVER + '/api/v1/paylink/register_payment_channel', {
            method: 'POST',
            body: JSON.stringify(this.clearStateForPost(channel)),
        }).then(res => res.json())
            .then(
                (response) => {
                    if (!response.success){
                        console.log("Register payment channel", response);
                        return;
                    }
                    else {
                        alert('Ya notificamos a '+this.state.orderInfo.merchant_display_name);
                        window.location.href = '/payment-instructions/'+this.props.match.params.order_id;
                    }
                },
                (err) => {
                    alert('Error: ' + err);
                }
            );
    }
    render(){
        const orderData = this.state.orderInfo;
        const status = this.state.orderInfo.pos_status;
        const {posLandingConfig, showMessage}= this.state;
        const apurataPosCard = this.getApurataPosCard(orderData, posLandingConfig);
        const {merchant_channel,demo }= this.state.orderInfo;
        //orderData.payment_status = 'CANCELED';
        /* Financing messages */
        if(status!== null && status!=='created' && showMessage){
            return (
                <div className="container-fluid h-100">
                    <div style={{height:50}}/>
                    <Navigation/>
                    { this.getMessageByStatus(orderData) }
                </div>
            )
        }
        else{
            if(orderData.payment_status==="PENDING"){ /* Transaction / Yape / Plin */
                return(
                    <div className="container-fluid h-100">
                        <div style={{height:50}}/>
                        <Navigation/>
                        <div className="row head-instructions">
                            <div className="col-md-12">
                                <h4>Completa tu compra</h4>
                                <div className="row">
                                    <div className="col-md-6 offset-md-3">
                                        <div className="order" id="openSteps">
                                            <br/>
                                            <h5>{ orderData.merchant_display_name }</h5>
                                            <div className="row">
                                                <div className="col-6">
                                                    <p><i className="fas fa-shopping-cart"></i> { orderData.description }</p>
                                                </div>
                                                <div className="col-6">
                                                    <p><i className="far fa-money-bill-alt"></i> S/. { orderData.amount }</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                {
                                    this.state.stepsVisible?(
                                        <div className="row">
                                            <div className="col-md-10 offset-md-1">
                                                <h5 className="font-weight-bold"><i className="fas fa-arrow-left" onClick={()=>this.back()}></i> {this.state.selectOption.name}</h5><br/>
                                                <div className="row">
                                                    <div className={'col-6 '+(this.state.swapStep? "active":"")} onClick={()=>this.changeStep(true)}>
                                                        <div className="step">Paso 1</div>
                                                    </div>
                                                    <div className={'col-6 '+(!this.state.swapStep? "active":"")} onClick={()=>this.changeStep(false)}>
                                                        <div className="step">Paso 2</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ):(<div/>)
                                }

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                {   /* Show cards and steps */
                                    this.state.stepsVisible?(
                                        this.state.swapStep?(
                                            <div className="step-content">
                                                <div className="text-center info">Realiza el deposito a cualquiera de estas cuentas.</div>
                                                <CardsByOption cards={this.state.selectOption.cards} creditCards={creditCards} order={orderData}></CardsByOption>
                                                <div className="text-center info">Cuando hagas la transferencia, vuelve y continua el siguiente paso.</div>
                                                <div className="boton">
                                                    <a onClick={()=>this.changeStep(false)} className="btn btn-block btn-orange">Ir al Paso 2</a>
                                                </div>
                                            </div>
                                        ):(
                                            !this.state.optionYape?(
                                                <div className="step-content">
                                                    <div className="text-center info">Sube una foto o captura de pantalla de la constancia de transferencia o voucher.</div>
                                                    <div className="boton">
                                                        <a href={"/register-payment/"+ this.props.match.params.order_id } className="btn btn-block btn-orange">Registrar Pago</a>
                                                    </div>
                                                </div>
                                            ):(
                                                <div className="step-content">
                                                    <div className="text-center info">Ahora avisa que ya hiciste el pago a {orderData.merchant_display_name}</div>
                                                    <div className="boton">
                                                        <a onClick={()=>this.channelSubmit('YAPE')} className="btn btn-block btn-activate purple">Ya hice mi pago por Yape</a>
                                                    </div>
                                                    <hr/>
                                                    <div className="boton">
                                                        <a onClick={()=>this.channelSubmit('PLIN')} className="btn btn-block btn-activate turqueza">Ya hice mi pago por PLin</a>
                                                    </div>
                                                </div>
                                            )
                                        )
                                    ):( /* Show Channels */
                                        <ChannelsOptions
                                            merchant_channel={merchant_channel}
                                            apurataPosCard={apurataPosCard}
                                            changeOption={this.changeOption.bind(this)}
                                            comingSoon={this.comingSoon}/>
                                    )
                                }
                            </div>
                        </div>
                        <Footer/>
                    </div>
                );
            }
            else {
                return (
                    <div className="container-fluid h-100">
                        <div style={{height:50}}/>
                        <Navigation/>
                        <MessagePaymentStatus status={orderData.payment_status} merchant={orderData.merchant_display_name}/>
                    </div>
                )
            }
        }

    }
}
const categories = [
    {
        name: "Transferencia Directa",
        cards: [1,2,4],
    },
    {
        name: "Transferencia Interbancaria",
        cards: [3],
    },
    {
        name: "Pago en Agente",
        cards: [1,2],
    },
    {
        name: "Pago por Yape o Plin",
        cards: [5,6],
    }
    /*{
        name: "Pago en Agencias",
        cards: [1,2,4],
    },
    */
]

const creditCards = [
    {
        id: 1,
        logo: "bcp.png",
        title: "Tecno Créditos SAC",
        content: "Cuenta BCP: <span style='font-size: 17px;font-weight: bold;'>193-2532653-0-52</span>",
        account: "1932532653052"
    },
    {
        id:2,
        logo: "bbva.png",
        title: "Tecno Créditos SAC",
        content: "Cuenta BBVA: <span style='font-size: 17px;font-weight: bold;'>0011-0369-01-00029461</span>",
        account: "001103690100029461"
    },
    {
        id:3,
        logo: "bbva.png",
        title: "Tecno Créditos SAC",
        content: "CCI BBVA: <span style='font-size: 17px;font-weight: bold;'>01136900010002946169</span>",
        account: "01136900010002946169"
    },
    {
        id:4,
        logo: "inter.png",
        title: "Tecno Créditos SAC",
        content: "Cuenta Corriente: <span style='font-size: 17px;font-weight: bold;'>057-3001254804</span>",
        account: "0573001254804"
    },
    {
        id:5,
        logo: "yape.png",
        color: "purple",
        phone: "yape_phone",
        status: "yape_status"
    },
    {
        id:6,
        logo: "plin.png",
        color: "turqueza",
        phone: "plin_phone",
        status: "plin_status"
    }
]

const messageByStatus=[
    {
        status: "approved",
        title: "Tu financiamiento ha sido aprobado",
        message: "Gracias por tu compra en",
        icon: "far fa-check-circle",
        btnText: "Valida tu identidad aquí",
        btnOptions: false
    },{
        status: "validated confirmed funded",
        title: "Tu compra se realizó con éxito",
        message: "programará la entrega de tu compra",
        icon: "fas fa-shopping-cart",
        btnText: "Mira tu calendario de pagos aquí",
        btnOptions: false
    },{
        status: "rejected",
        title: "Lo sentimos",
        message: "Tu solicitud no pudo ser aprobada",
        icon: "far fa-frown-open",
        btnOptions: true
    },{
        status: "canceled",
        title: "Lo sentimos",
        message: "Tu solicitud fue cancelada",
        icon: "far fa-times-circle",
        btnOptions: true
    },{
        status: "onhold",
        title: "Validando solicitud",
        message: "Sólo tienes que esperar un poco...",
        icon: "far fa-check-circle",
        btnOptions: true
    },
]