import React,{Component} from 'react';
import {toast} from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';


export default class CreateOrderButton extends Component {
    constructor(props) {
        super(props);
        this.onClickDisabled = this.onClickDisabled.bind(this);
    }

    onClickDisabled() {
        toast.dark('Debemos verificar tus datos primero! Consultas o reclamos al 943 689 341', {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
        });
    }

    render() {
        if (this.props.user.enable) {
            return(
                <a href="/create-order/"
                   className="btn btn-lg btn-block btn-orange">
                    <i className="fas fa-plus" /> Crear Orden
                </a>
            )
        } else {
            return (
                <a href="/create-order/"
                   className="btn btn-lg btn-block btn-orange">
                    <i className="fas fa-plus" /> Crear Orden Demo
                </a>
            )
        }
    }
}