import React,{Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import Clipboard from 'react-clipboard.js';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';


export default class ShareButton extends Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        const shareData = {
            title: this.props.title,
            text: this.props.text,
            url: this.props.url,
            icon: this.props.icon,
        }

        if (navigator.share) {
            try {
                navigator.share(shareData)
            } catch (err) {
                alert('No se puede compartir: ' + err);
            }
        } else {
            alert('Dispositivo no compatible');
        }
    }

    onCopied() {
        toast.dark('Copiado!', {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
        });
    }

    render() {
        if (navigator.share) {
            return (
                <a>
                    <FontAwesomeIcon
                        icon={faShareAlt}
                        onClick={this.onClick}
                    />
                </a>
            )
        } else {
            return (
                <Clipboard component="a" data-clipboard-text={this.props.url} onSuccess={this.onCopied}>
                    {
                        this.props.icon==null? (
                            <FontAwesomeIcon
                                icon={faShareAlt}
                            />
                        ):(
                            <i className={this.props.icon+" "}></i>
                        )
                    }

                </Clipboard>
            )
        }
    }
}