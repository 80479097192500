import React, { Component, useState } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { toast } from "react-toastify";

import Navigation from "../components/Navigation";
import { getHeaders } from "../services/AuthServices";
import { fetchMerchantInfo, fetchBanksList } from "../services/UserServices";

function Accounts({ userInfo }) {
    //console.log(userInfo.bank_accounts);
    const listInfo = userInfo.bank_accounts || [];
    return (
        <div className="row">
            {
                listInfo.map((account) => {
                    return (
                        <div className="col-md-12" key={account.bank_name}>
                            <div>{account.bank_name}</div>
                            <div className="row">
                                <div className="col-5">N° de Cuenta</div>
                                <div className="col-7 text-right">{account.bank_account_no}</div>
                            </div>
                            <div className="row">
                                <div className="col-5">CCI</div>
                                <div className="col-7 text-right">{account.bank_cci}</div>
                            </div>
                            <br/>
                        </div>
                    )
                })
            }
        </div>
    )
}

function RowOptions({ channel, openModal, index, channels }) {
    const copyChannels = channels || {}
    return (
        <div className="col-md-12">
            <div className="row">
                <div className="col-5"><b>{channel.name}</b> {copyChannels[channel.phone]?' - '+copyChannels[channel.phone]:''}</div>
                {
                    copyChannels[channel.status] === 'DISABLED' ? (
                        <div className="col-7 text-right">
                            <a onClick={() => openModal(true, index)} className={"btn-activate " + channel.color}>Activar</a>
                        </div>
                    ) : (
                            <div className="col-7 text-right">
                                <div>{copyChannels[channel.status] === 'REQUESTED' ? ('Pendiente') : ('Activo')}</div>
                            </div>
                        )
                }

            </div>
            <hr />
        </div>
    );
}


function ModalViewChannel({visible, setVisibility, channelSelect, storageChannel, userInfo}) {
    const [phone, setPhone] = useState(userInfo.phone);
    let channels = userInfo.channels;
    return (
        <Modal
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            open={visible}
            onClose={() => setVisibility(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 100,
            }}>
            <Fade in={visible}>
                {
                    channelSelect ? (
                        <div className="container-fluid p-2" style={{ backgroundColor: "white", width: 400, margin: 10 }}>
                            <p className="text-center pt-3" style={{ fontWeight: 'bold' }}>Activar {channelSelect.name}</p>
                            <div className="row p-3">
                                <div className="offset-md-1 col-md-10">
                                    {
                                        channelSelect.needPhone ? (
                                            <div className="form-group">
                                                <label>Número de celular de {channelSelect.name}</label>
                                                <input type="text" className="form-control" pattern="[0-9]*"
                                                    value={phone}
                                                    onChange={ e =>setPhone(e.target.value)}
                                                    name={channelSelect.phone}
                                                    required />
                                            </div>
                                        ) : (
                                                <div className="text-center">
                                                    <p className="text-center">Para activar esta opción necesitas agregar al menos un número de cuenta.</p>
                                                    <small><i className="far fa-check-circle gold"></i> Apurata garantiza a tu cliente.</small><br /> <br />
                                                </div>
                                            )
                                    }
                                    <button onClick={() => {
                                        if (channelSelect.needPhone)
                                             channels[channelSelect.phone] = phone;
                                        storageChannel(channelSelect, channels)
                                    }
                                    } className="btn btn-form btn-block">Guardar</button>
                                    <br />
                                </div>
                            </div>
                        </div>) : (<div />)
                }
            </Fade>
        </Modal>
    );
}

export default class PaymentForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userInfo: {},
            errorMsg: {},
            channelSelect: {},
            modalBankVisible: false,
            modalChannelVisible: false,
            /* bank_accounts */
            bank_name: '',
            bank_account_no: '',
            bank_cci: '',
            /* channels */
            financing_status: '',
            banks_status: '',
            yape_status: '',
            plin_status: '',
            yape_phone: '',
            plin_phone: '',
            banks_list:[]

        };
        this.setVisibilityBankModal = (val) => { this.setState({ modalBankVisible: val }) };

        this.setVisibilityChannelModal = (visible, index) => {
            this.setState({ modalChannelVisible: visible, channelSelect: allchannels[index] });
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.addAccountForPost = this.addAccountForPost.bind(this);
        this.storageUserProfile = this.storageUserProfile.bind(this);

        this.storageChannel = this.storageChannel.bind(this);
    }
    componentDidMount() {
        
        this.getUserInfo();
        fetchBanksList().then(
            (info) => {
                this.setState({banks_list:info})
                console.log("banks_list",this.state.banks_list);
            }
        );
    }

    getUserInfo(){
        fetchMerchantInfo().then(
            (info) => {
                if (Object.keys(info).length === 0) {
                    window.location.href = process.env.REACT_APP_API_SERVER + '/logout?next=/login?next=/paylink';
                    return;
                }
                this.setState({ userInfo: info });
                //console.log(this.state.userInfo);
                document.getElementsByClassName('dashboard-curtain')[0].classList.remove('active');
            }
        );
    }

    handleInputChange(event) {
        //console.log("event", event);
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
        //console.log(this.state);
    }

    addAccountForPost() {
        const bankAccounts = this.state.userInfo.bank_accounts;
        const newAccount = {
            bank_name: this.state.bank_name,
            bank_account_no: this.state.bank_account_no,
            bank_cci: this.state.bank_cci,
        };
        //console.log([...bankAccounts, newAccount]);
        return {
            bank_accounts: [...bankAccounts, newAccount],
        };
    }
    storageUserProfile(event) {
        fetch(process.env.REACT_APP_API_SERVER + "/api/v1/paylink/merchant", {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(this.addAccountForPost()),
            headers: getHeaders()
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (!result.success){
                        console.log(result);
                        return;
                    }
                    else {
                        this.setVisibilityBankModal(false);
                        alert('Se guardo exitosamente');
                        window.location.href = '/profile';
                    }
                },
                (error) => {
                    console.error(error);
                }
            )
    }

    clearChannelsForPost(channelSelect, channels) {
        if (channelSelect !== undefined)
            channels[channelSelect.status] = 'REQUESTED';
        return {
            channels: channels
        };
    }
    storageChannel(channelSelect, channels) {
        console.log("channelSelect", channelSelect);
        if(!channelSelect.needPhone && this.state.userInfo.bank_accounts.length<=0){
            this.setVisibilityChannelModal(false,channelSelect);
            this.onMissingAccount();
        }else{
            fetch(process.env.REACT_APP_API_SERVER + "/api/v1/paylink/merchant", {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.clearChannelsForPost(channelSelect, channels)),
                headers: getHeaders()
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setVisibilityChannelModal(false,channelSelect);
                    },
                    (error) => {
                        console.error(error);
                    }
                )
        }
    }

    onMissingAccount() {
        toast.dark('Para activar primero necesitas agregar un número de cuenta.', {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
        });
    }
    render() {
        const { userInfo, channelSelect, banks_list } = this.state;
        return (
            <div className="h-100">
                <div style={{ height: 50 }} />
                <Navigation />
                <div className="dashboard-curtain active" />
                <img className="bg-profile" src={process.env.PUBLIC_URL + "/img/bg-ondas.png"} alt="Logo" width="100%" />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <p style={{ marginTop: -30 }}>Perfil</p>
                            <h4>{userInfo.display_name}</h4>
                            <hr />
                            <div className="row">
                                <div className="col-5"><p>RUC</p></div>
                                <div className="col-7 text-right"><b>{userInfo.ruc}</b></div>
                            </div>
                            <div className="row">
                                <div className="col-5"><p>Razón Social</p></div>
                                <div className="col-7 text-right"><b>{userInfo.razon_social}</b></div>
                            </div>
                            <div className="row">
                                <div className="col-5"><p>Usuario</p></div>
                                <div className="col-7 text-right"><b>{userInfo.contact_name}</b></div>
                            </div>
                            <div className="row">
                                <div className="col-5"><p>Celular</p></div>
                                <div className="col-7 text-right"><b>{userInfo.phone}</b></div>
                            </div>
                            <div className="row">
                                <div className="col-5"><p>Correo</p></div>
                                <div className="col-7 text-right"><b>{userInfo.notification_emails}</b></div>
                            </div>
                            <div className="row">
                                <div className="col-5"><p>Estado</p></div>
                                <div className="col-7 text-right"><b>{userInfo.enable ? 'Activo' : 'Desactivo'}</b></div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-7"><p><b>Números de cuenta</b></p></div>
                                <div className="col-5 text-right"><a onClick={() => this.setVisibilityBankModal(true)} className="add">+ Agregar</a></div>
                            </div>
                            <Accounts userInfo={userInfo} />
                            <hr />
                            <p>Opciones de Pago</p>
                            <div className="row">
                                {
                                    allchannels.map((channel, index) => {
                                        const channels = userInfo.channels === undefined ? {} : userInfo.channels;
                                        return (
                                            <RowOptions key={index} channel={channel} index={index} openModal={this.setVisibilityChannelModal} channels={channels} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    open={this.state.modalBankVisible}
                    onClose={() => this.setVisibilityBankModal(false)}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 100,
                    }}>
                    <Fade in={this.state.modalBankVisible}>
                        <div className="container-fluid p-2" style={{ backgroundColor: "white", width: 400, margin: 10 }}>
                            <p className="text-center pt-3" style={{ fontWeight: 'bold' }}>Agregar cuenta bancaria</p>
                            <div className="row p-3">
                                <div className="offset-md-1 col-md-10">
                                    {/*onSubmit={e => e.preventDefault()}*/}
                                    <form  onSubmit={e => e.preventDefault()}>
                                        <div className="form-group">
                                            <label>Banco</label>
                                            <select className="form-control" id="bank_name"
                                                    name="bank_name"
                                                    onChange={this.handleInputChange} required>
                                                <option value="">Seleccione una opción</option>
                                                {
                                                    banks_list.map( (bank,index)=>{
                                                        return (
                                                            <option value={bank} key={index}>{bank}</option>
                                                        )
                                                    })

                                                }
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Numero de cuenta (debe coincidir con el RUC)</label>
                                            <input type="text" className="form-control" placeholder="xxx-xxx-xxxxx"
                                                   name="bank_account_no"
                                                   onChange={this.handleInputChange} required />
                                        </div>
                                        <div className="form-group">
                                            <label>Numero de cuenta Interbancario (CCI)</label>
                                            <input type="text" className="form-control" placeholder="xxx-xxx-xxxxx"
                                                   name="bank_cci"
                                                   onChange={this.handleInputChange} />
                                        </div>
                                        <button onClick={this.storageUserProfile} className="btn btn-form btn-block">Guardar</button>
                                        <br />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </Modal>

                {
                    this.state.modalChannelVisible?(
                        <ModalViewChannel
                            visible={this.state.modalChannelVisible}
                            setVisibility = {this.setVisibilityChannelModal.bind(this)}
                            channelSelect = {channelSelect}
                            storageChannel = {this.storageChannel}
                            userInfo = {userInfo}
                        />
                    ):(<div/>)
                }

            </div>

        );
    }
}

const allchannels = [
    {
        name: "Financiamiento",
        needPhone: false,
        color: "green",
        status: "financing_status",
    }, {
        name: "Transferencias",
        needPhone: false,
        color: "green",
        status: 'transaction_status',
    }, {
        name: "Yape",
        needPhone: true,
        color: "purple",
        status: 'yape_status',
        phone: 'yape_phone'
    }, {
        name: "Plin",
        needPhone: true,
        color: "turqueza",
        status: 'plin_status',
        phone: 'plin_phone'
    }
]
