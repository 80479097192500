import React, {Component} from 'react';
import Navigation from './../components/Navigation';
import { getHeaders } from './../services/AuthServices'
import { fetchMerchantInfo } from './../services/UserServices';

export default class OrderForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            payerPhone: '',
            payerName: '',
            description: '',
            amount: '',
            payerEmail: '',
            paymentDate: new Date().toISOString().substring(0, 10),
            errorMsg: {},
            userInfo: {},
            phone:'',
            loading: false,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.clearStateForPost = this.clearStateForPost.bind(this);
    }

    componentDidMount() {
        fetchMerchantInfo()
            .then(
                (result) => {
                    if (result) {
                        this.setState({
                            userInfo: result,
                        });
                        if (!result.enable)
                            this.setState({payerPhone:result.phone})
                    }
                    console.log("user Info",result);
                }
            )
    }

    clearStateForPost() {
        return {
            payer_phone: this.state.payerPhone,
            payer_name: this.state.payerName,
            payer_email: this.state.payerEmail,
            amount: this.state.amount === '' ? 0 : this.state.amount,
            description: this.state.description,
            expired_date: this.state.paymentDate
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({loading: true});
        fetch(process.env.REACT_APP_API_SERVER + '/api/v1/paylink/register_order', {
            method: 'POST',
            body: JSON.stringify(this.clearStateForPost()),
            headers: getHeaders()
        }).then(res => res.json())
            .then(
                (response) => {
                    if (!response.success) {
                        this.setState({ errorMsg: response.errors })
                        console.log(this.state.errorMsg);
                        return;
                    }
                    else {
                        alert('Orden generada exitosamente')
                        window.location.href = '/dashboard';
                    }
                },
                (err) => {
                    alert('Error: ' + err);
                }
            )
            .finally(() => this.setState({loading: false}));
    }

    render() {
        const { userInfo, loading } = this.state;
        return (
            <div className="container-fluid h-100 bg-form">
                <div style={{height:50}}/>
                <Navigation/>
                <div className="row form-pay vertical-center">
                    <div className="col-md-8 offset-md-2">
                        <br/>
                        <h4>Crear Orden</h4>
                        <hr />
                        <div className="order-form">
                            <form onSubmit={this.handleSubmit}>

                                <div className="form-group">
                                    <label htmlFor="">Celular de tu cliente {userInfo.enable?"":"(demo)"} </label>
                                    {
                                        userInfo.enable?(
                                            <input
                                                className="form-control"
                                                name="payerPhone"
                                                type="text"
                                                onChange={this.handleInputChange}/>
                                        ):(
                                            <input
                                                className="form-control"
                                                name="payerPhone"
                                                type="text"
                                                value={userInfo.phone} disabled={true}/>
                                        )
                                    }
                                    <ValidationMessage errors={this.state.errorMsg} name="payer_phone" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="">Nombre de tu cliente (opcional)</label>
                                    <input
                                        className="form-control"
                                        name="payerName"
                                        type="text"
                                        onChange={this.handleInputChange} />
                                    <ValidationMessage errors={this.state.errorMsg} name="payer_name" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Correo electrónico de tu cliente (opcional)</label>
                                    <input
                                        className="form-control"
                                        name="payerEmail"
                                        type="email"
                                        onChange={this.handleInputChange} />
                                    <ValidationMessage errors={this.state.errorMsg} name="payer_email" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Monto S/</label>
                                    <input
                                        className="form-control"
                                        name="amount"
                                        type="number"
                                        step="0.01"
                                        onChange={this.handleInputChange} />
                                    <ValidationMessage errors={this.state.errorMsg} name="amount" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Descripción de la orden</label>
                                    <textarea
                                        className="form-control"
                                        name="description"
                                        onChange={this.handleInputChange} />
                                    <ValidationMessage errors={this.state.errorMsg} name="description" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Fecha de pago</label>
                                    <input
                                        className="form-control"
                                        name="paymentDate"
                                        type="date"
                                        value={this.state.paymentDate}
                                        onChange={this.handleInputChange} />
                                    <ValidationMessage errors={this.state.errorMsg} name="payment_date" />
                                </div>
                                <button type="submit" className="btn btn-form btn-block" disabled={this.state.loading}>
                                    {loading ? 'Creando...' : 'Crear Orden'}
                                </button>
                            </form><br/>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

function ValidationMessage(props) {
    if (props.errors && props.errors[props.name] !== undefined) {
        return <small className='text-danger'>{props.errors[props.name][0]}</small>
    }
    return null;
}
